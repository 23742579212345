import { atom } from 'jotai';
import z from 'zod';

const ZCartInput = z.object({
  id: z.string(),
});

export type CartAtom = z.infer<typeof ZCartInput>;

export const cartAtom = atom<z.infer<typeof ZCartInput> | undefined>(undefined);
