import { Box, CSSReset, ChakraProvider, extendTheme } from '@chakra-ui/react';
import { theme as leafTheme } from '@getjust/leaf';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Hydrate, MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import mixpanel from 'mixpanel-browser';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import posthog from 'posthog-js';
import { useEffect, useRef, useState } from 'react';

import type { DehydratedState } from '@tanstack/react-query';
import type { AppProps } from 'next/app';

import { ErrorBoundary } from '$components/ErrorBoundary';
import '../styles/globals.css';
import '../styles/OverlaySpinner.css';
import 'react-phone-number-input/style.css';
import '@getjust/leaf/dist/style.css';
import { InitWrapper } from '$src/components/InitWrapper';
import { REFS_MAP } from '$src/constants';
import { AuthProvider } from '$src/hooks/auth';
import { useROFormMessageAtom } from '$src/hooks/state';
import { reportQueryError } from '$src/http';
import { initAmplitude } from '$src/utils/amplitude';

type Props = {
  dehydratedState: DehydratedState;
  cartId: string;
  sellerId: string;
};

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY!);

const theme = extendTheme({
  ...leafTheme,
  styles: {
    global: () => ({
      body: {
        bg: '#fffff',
      },
    }),
  },
});

/**
 * FAQ:
 * - Why no jotai (state) provider in the react tree ?
 * cf: https://github.com/pmndrs/jotai/discussions/384
 */

function App({ Component, pageProps }: AppProps<Props>) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError: (error: any) => {
            reportQueryError(error);
          },
        }),
        queryCache: new QueryCache({
          onError: (error: any) => {
            reportQueryError(error);
          },
        }),
        defaultOptions: {
          queries: {
            staleTime: 5 * (60 * 1000), // 10 mins
            cacheTime: 10 * (60 * 1000), // 10 mins
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
          },
        },
      }),
  );
  const portalRef = useRef<HTMLDivElement>();
  const message = useROFormMessageAtom();

  useEffect(() => {
    initAmplitude();
  }, []);

  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY as string, {
      api_host: 'https://app.posthog.com',
      loaded: (params) => {
        if (
          process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' ||
          !process.env.NEXT_PUBLIC_ENABLE_ANALYTICS
        ) {
          params.opt_out_capturing();
        }
      },
      autocapture: true,
      save_referrer: true,
    });
  }, []);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' &&
      process.env.NEXT_PUBLIC_ENABLE_ANALYTICS &&
      process.env.NEXT_PUBLIC_MIXPANEL_API_KEY
    ) {
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_API_KEY, {
        api_host: 'https://api-eu.mixpanel.com',
        autotrack: true,
      });
    }
  }, []);

  useEffect(() => {
    if (portalRef.current) {
      REFS_MAP.set('portal', portalRef);
    }
  }, []);

  useEffect(() => {
    // slide animation for mobiles
    if (!portalRef.current) return;
    const myTimeout = setTimeout(() => {
      portalRef.current?.classList.add('slideIn');
    }, 200);
    return () => clearTimeout(myTimeout);
  }, []);

  return (
    <>
      <Head>
        <title>JUST Checkout</title>
        <meta name="description" content="One click checkout" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Elements
        stripe={stripePromise}
        options={{
          locale: message?.language ?? 'fr',
          fonts: [
            {
              cssSrc: 'https://marketing-assets.vercel.app/Beausite.css',
            },
            {
              cssSrc: 'https://marketing-assets.vercel.app/Brice.css',
            },
          ],
        }}
      >
        <ChakraProvider resetCSS theme={theme}>
          <CSSReset />
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                <InitWrapper>
                  <Box id="just-form-layout" ref={portalRef as any}>
                    <AuthProvider>
                      <Component {...pageProps} />
                    </AuthProvider>
                  </Box>
                </InitWrapper>
              </Hydrate>
            </QueryClientProvider>
          </ErrorBoundary>
        </ChakraProvider>
      </Elements>
    </>
  );
}

export default appWithTranslation(App);
