import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { useCartAtom, useEmailAtom, useFormMessageAtom } from '$hooks/state';
import { CART_QUERY_KEY, DEFAULT_EMAIL_ADDRESS } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { usePostMessage } from '$src/hooks/client/usePostMessage';
import { useValidateEmail } from '$src/hooks/mutations/useValidateEmail';
import { gateway } from '$src/http';
import { UpdateEmailInput } from '$src/pages/api/[shopId]/[cartId]/update-email';

const MUTATION_UPDATE_EMAIL_KEY = 'MUTATION/UPDATE_EMAIL';

export const useUpdateEmail = () => {
  const { onCartChange } = useCartAtom();
  const baseUrl = useBaseApiUrl();
  const { onEmailChange } = useEmailAtom();
  const { postMessage } = usePostMessage();
  const { validateEmail } = useValidateEmail();
  const { formMessage } = useFormMessageAtom();

  const queryClient = useQueryClient();
  return useMutation((input: UpdateEmailInput) => gateway.post<Cart>(`${baseUrl}/update-email`, input), {
    mutationKey: [MUTATION_UPDATE_EMAIL_KEY],
    onSuccess: async (data, payload) => {
      onCartChange({ id: data!.data.id });
      if (data?.data?.customer?.email !== DEFAULT_EMAIL_ADDRESS) {
        postMessage({
          emitter: 'JUST_FORM',
          action: 'SET_SESSION_ID',
          cartId: data!.data?.id,
        });
      }
      queryClient.setQueryData<Cart>([CART_QUERY_KEY], data?.data);

      const email =
        data?.data?.customer?.email && data.data.customer.email !== ''
          ? data.data.customer.email
          : payload.email;

      // NOTE: If the user has a Prestashop account AND is not connected to its account
      if (data?.data?.customer?.hasAccount && email && formMessage?.currentUser?.email !== email) {
        await validateEmail(email);
      }
      onEmailChange(email);
    },
  });
};
