import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { gateway } from '$src/http';
import { AddLineItemsInput } from '$src/pages/api/[shopId]/[cartId]/add-line-items';

export const MUTATION_CART_ADD_LINE_ITEMS_KEY = 'MUTATION/CART_ADD_LINE_ITEMS';

export const useAddLineItems = () => {
  const queryClient = useQueryClient();
  const baseUrl = useBaseApiUrl();

  return useMutation((items: AddLineItemsInput) => gateway.post<Cart>(`${baseUrl}/add-line-items`, items), {
    mutationKey: [MUTATION_CART_ADD_LINE_ITEMS_KEY],
    onSuccess: ({ data }) => {
      queryClient.setQueryData<Cart>([CART_QUERY_KEY], data);
    },
  });
};
