import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';

import type { PaymentStatusAtom } from '$business/atoms/payment-status';

import { paymentStatusAtom } from '$business/atoms/payment-status';
import { reportStateError } from '$src/errors';
import { ZPaymentStatusSchema } from '$src/models/state/payment-status';

export function usePaymentStatusAtom() {
  const [paymentStatus, setPaymentStatus] = useAtom(paymentStatusAtom);

  const onPaymentStatusChange = useCallback(
    (status: PaymentStatusAtom) => {
      const safeMessage = ZPaymentStatusSchema.safeParse(status);

      if (safeMessage.success) {
        setPaymentStatus(safeMessage.data);
      } else {
        reportStateError(safeMessage.error, {
          tags: {
            hook: usePaymentStatusAtom.name,
          },
        });
      }
    },
    [setPaymentStatus],
  );

  return { paymentStatus, onPaymentStatusChange };
}

export function useROPaymentStatusAtom() {
  return useAtomValue(paymentStatusAtom);
}
