import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';
import z from 'zod';

import { emailAtom } from '$business/atoms/email';
import { reportStateError } from '$src/errors';

export function useEmailAtom() {
  const [email, setEmailAtom] = useAtom(emailAtom);

  const onEmailChange = useCallback((nextEmail?: string) => {
    if (!nextEmail) {
      setEmailAtom(undefined);
      return;
    }
    const safe = z
      .string()
      .email()
      .refine((value: string) => {
        return /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~@.]+$/.test(value); // allowed email characters
      })
      .safeParse(nextEmail);

    if (safe.success) {
      setEmailAtom(safe.data);
    } else {
      reportStateError(safe.error, {
        tags: {
          hook: useEmailAtom.name,
        },
      });
    }
  }, []);
  return { email, onEmailChange };
}

export function useROEmailAtom() {
  const loginInfo = useAtomValue(emailAtom);

  return loginInfo;
}
