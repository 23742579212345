import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import type { JustApiToken } from '$src/types';

import { USER_INFO_QUERY_KEY } from '$src/constants';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { authAPI } from '$src/http';

export type RefreshTokenPayload = {
  refresh_token: string;
  client_secret: string;
  client_id: string;
};

const REFRESH_TOKEN_MUTATION_KEY = 'MUTATION/REFRESH_TOKEN';

export function useRefreshToken(): () => Promise<AxiosResponse<JustApiToken>> {
  const { track } = useTrackAmplitude();

  const queryClient = useQueryClient();

  const mutation = useMutation(() => authAPI.post<JustApiToken>('/refresh'), {
    mutationKey: [REFRESH_TOKEN_MUTATION_KEY],
    retry: false,
    onSuccess: async () => {
      track('Customer logged in');
      await queryClient.invalidateQueries([USER_INFO_QUERY_KEY]);
    },
  });

  return () => mutation.mutateAsync();
}
