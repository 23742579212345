import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { gateway } from '$src/http';
import { DeleteDiscountInput } from '$src/pages/api/[shopId]/[cartId]/delete-discount';

const MUTATION_DELETE_DISCOUNT_KEY = 'MUTATION/DELETE_DISCOUNT';

export const useDeleteDiscount = () => {
  const baseUrl = useBaseApiUrl();
  const queryClient = useQueryClient();

  return useMutation(
    (discount: DeleteDiscountInput) => gateway.post<Cart>(`${baseUrl}/delete-discount`, discount),
    {
      mutationKey: [MUTATION_DELETE_DISCOUNT_KEY],
      onSuccess: ({ data }) => {
        queryClient.setQueryData<Cart>([CART_QUERY_KEY], data);
      },
    },
  );
};
