import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import type { CheckoutV1 } from '@getjust/api-schema';

import { USER_INFO_QUERY_KEY } from '$src/constants';
import { justAPI } from '$src/http';

const ADD_SOURCE_MUTATION_KEY = 'MUTATION/ADD_SOURCE';

export const useAddSource = () => {
  const { t } = useTranslation('common');
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation(
    (data: CheckoutV1.SourcesCreateSourceRequestBody) =>
      justAPI.post<CheckoutV1.SourcesCreateSourceResponseBody>('/v1/sources', data),
    {
      mutationKey: [ADD_SOURCE_MUTATION_KEY],
      onError: (error: any) => {
        const errorCode = error.response.data?.decline_code ?? error.response.data?.code ?? 'card_declined';

        toast({
          description: t(`errors.stripe.${errorCode}`),
          status: 'error',
          position: 'top',
          isClosable: true,
          duration: 10000,
        });
      },
      onMutate: async ({ card }) => {
        await queryClient.cancelQueries({ queryKey: [USER_INFO_QUERY_KEY] });
        const previousUser = queryClient.getQueryData<CheckoutV1.AuthMeResponseBody>([USER_INFO_QUERY_KEY]);
        queryClient.setQueryData<CheckoutV1.AuthMeResponseBody>(
          [USER_INFO_QUERY_KEY],
          (oldUser): CheckoutV1.AuthMeResponseBody | undefined => {
            const copySources = [...(oldUser?.sources ?? [])];
            copySources.forEach((source) => (source.is_default = 0));
            copySources.push({
              card: {
                billing_address: '',
                billing_email: '',
                billing_phone: '',
                brand: card.brand,
                company: '',
                created_at: new Date().toISOString(),
                exp_month: card.exp_month,
                exp_year: card.exp_year,
                last_4: card.last4,
                name: card.name,
                updated_at: new Date().toISOString(),
              },
              id: card.id,
              is_default: 1,
            });
            return {
              ...(oldUser as CheckoutV1.AuthMeResponseBody),
              sources: copySources,
            };
          },
        );
        return {
          previousUser,
        };
      },
      onSuccess: () => {
        queryClient.invalidateQueries([USER_INFO_QUERY_KEY]);
      },
    },
  );
};
