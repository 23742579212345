import { useToast } from '@chakra-ui/react';
import { CheckoutV2 } from '@getjust/api-schema';
import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { useQueryError, useRouter } from '$hooks/client';
import { useROLoginAtom } from '$hooks/state';
import { isPrefilledAtom } from '$src/business/atoms/is-prefilled';
import { justAPI } from '$src/http';

const MUTATION_VALIDATE_EMAIL_CHALLENGE_KEY = 'MUTATION/VALIDATE_EMAIL_CHALLENGE';

export const useValidateEmailChallenge = () => {
  const toast = useToast();
  const loginInfo = useROLoginAtom();
  const queryError = useQueryError({});
  const router = useRouter();
  const [_isPrefilled, setIsPrefilled] = useAtom(isPrefilledAtom);

  const mutation = useMutation(
    (values: CheckoutV2.AuthValidateEmailChallengeRequestBody) =>
      justAPI.post<CheckoutV2.AuthValidateEmailChallengeResponseBody>(
        '/v2/auth/validate-email-challenge',
        values,
      ),
    {
      mutationKey: [MUTATION_VALIDATE_EMAIL_CHALLENGE_KEY],
      onError: async (error: any) => {
        const title = queryError(error);
        toast({
          description: title,
          isClosable: true,
          status: 'error',
          position: 'top',
          duration: 20000,
        });
      },
      onSuccess: ({ data }) => {
        if (data.outcome !== 'challenged') {
          throw new Error('Failed to challenge');
        }
        setIsPrefilled(false); // allow form to be filled again with data account
        router.push('/unauthenticated');
      },
    },
  );

  const onValidateEmailChallenge = async (code: string) => {
    await mutation.mutateAsync({
      code: code.toString(),
      userId: loginInfo!.userId!,
      sessionId: loginInfo!.sessionId!,
    });
  };

  return {
    onValidateEmailChallenge,
    status: mutation.status,
  };
};
