import { useIsFetching, useQueryClient } from '@tanstack/react-query';

import { usePaymentStatusAtom } from '$hooks/state';
import { ORDER_REQUEST_QUERY_KEY } from '$src/constants';

export const useIsOrderProcessing = () => {
  const { paymentStatus } = usePaymentStatusAtom();

  const isOrderRequestFetching = useIsFetching([ORDER_REQUEST_QUERY_KEY, paymentStatus?.cartId]);
  const client = useQueryClient();
  const data = client.getQueryData<any>([ORDER_REQUEST_QUERY_KEY, paymentStatus?.cartId]);

  return Boolean(isOrderRequestFetching) || Boolean(data);
};
