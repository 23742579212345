import { WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';

import type { ErrorInfo } from 'react';

import { reportError } from '$src/errors';
import { _postMessage, captureMixpanelAnalytics, capturePosthogAnalytics } from '$src/utils';

export class ErrorBoundary extends React.Component<any, any> {
  // @ts-expect-error no need to handle error
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  // @ts-expect-error no need to handle error
  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  // @ts-expect-error no need to handle error
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    reportError(error, {
      tags: {
        errorCategory: 'errorBoundaryComponent',
        componentStack: errorInfo.componentStack,
      },
    });
  }

  // @ts-expect-error no need to handle error
  render() {
    const onAbort = () => {
      capturePosthogAnalytics('LEAVE_ON_ERROR');
      captureMixpanelAnalytics('LEAVE_ON_ERROR');

      return _postMessage({
        emitter: 'JUST_FORM',
        action: 'CLOSE',
      });
    };

    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="overlay show" style={{ margin: 0 }}>
          <div className="overlay-content" style={{ padding: 10 }}>
            <Stack spacing="4">
              <Box>
                <WarningTwoIcon fontSize="4xl" color="yellow.500" />
              </Box>
              <Text fontWeight="bold">Oops, there is an error!</Text>
              <HStack spacing="2" display="flex" justifyContent="center">
                <Button colorScheme="red" variant="ghost" onClick={onAbort}>
                  Go Back?
                </Button>
                <Button
                  colorScheme="green"
                  variant="ghost"
                  onClick={() => this.setState({ hasError: false })}
                >
                  Try again?
                </Button>
              </HStack>
            </Stack>
          </div>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}
