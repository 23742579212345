import { setStorage } from '@getjust/commons';
import axios, { AxiosInstance } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { LOGIN_URL, REFRESH_URL, USE_AUTH_LEGACY } from '$src/constants';
import { LegacyData } from '$src/types';

// This is used for legacy auth mode
let refreshToken = '';
let accessToken = '';
export const setRefreshToken = (token: string) => {
  refreshToken = token;
};
export const setAccessToken = (token: string) => {
  accessToken = token;
};

const isBrowser = typeof Window !== 'undefined';

const baseConfig = {
  baseURL: process.env.NEXT_PUBLIC_JUST_API_URL,
  timeout: 1 * 60 * 1000,
  withCredentials: USE_AUTH_LEGACY ? false : true, // pass cookies
};

const justAPI = axios.create(baseConfig);
const gateway = axios.create({
  ...baseConfig,
  baseURL: isBrowser ? new URL(window.location.href).origin : '',
});

const authAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_AUTH_API_URL,
  timeout: 1 * 60 * 1000,
  withCredentials: USE_AUTH_LEGACY ? false : true, // pass cookies
});

authAPI.interceptors.request.use(
  (config) => {
    config.headers['x-request-id'] = uuidv4();
    config.headers['authLegacy'] = USE_AUTH_LEGACY ? 'on' : 'off';
    if (USE_AUTH_LEGACY && accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
justAPI.interceptors.request.use(
  (config) => {
    config.headers['x-request-id'] = uuidv4();
    config.headers['authLegacy'] = USE_AUTH_LEGACY ? 'on' : 'off';
    if (USE_AUTH_LEGACY && accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
gateway.interceptors.request.use(
  (config) => {
    config.headers['x-request-id'] = uuidv4();
    config.headers['authLegacy'] = USE_AUTH_LEGACY ? 'on' : 'off';
    if (USE_AUTH_LEGACY && accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// refresh token
const refreshFunction = async (err: any, api: AxiosInstance) => {
  const originalConfig = err.config;
  if ([LOGIN_URL, REFRESH_URL].indexOf(originalConfig?.url) === -1 && err?.response) {
    // Access Token was expired
    if ((err.response.status === 401 || err.response.status === 403) && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        if (USE_AUTH_LEGACY) {
          const { data } = await authAPI.post<LegacyData>(REFRESH_URL, undefined, {
            headers: { refreshToken },
          });
          setRefreshToken(data.refreshToken);
          setAccessToken(data.accessToken);
          setStorage<LegacyData>('localStorage', 'token', data);
        } else {
          await authAPI.post(REFRESH_URL);
        }
        return justAPI(originalConfig);
      } catch (_error) {
        window.location.href = '/';
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(err);
};

justAPI.interceptors.response.use(
  (response) => response,
  async (err) => refreshFunction(err, justAPI),
);

gateway.interceptors.response.use(
  (response) => response,
  async (err: any) => refreshFunction(err, gateway),
);

export const setXLanguageGatewayHeader = (language: string) => {
  gateway.defaults.headers.common['x-language'] = language;
};

export const hasXLanguageGatewayHeader = () => {
  return !!gateway.defaults.headers.common['x-language'];
};

export const setXCountryGatewayHeader = (countryCode: string) => {
  gateway.defaults.headers.common['x-country'] = countryCode;
};

export const hasXCountryGatewayHeader = () => {
  return !!gateway.defaults.headers.common['x-country'];
};

export const setXSessionHeaders = () => {
  const justHeaders = justAPI.defaults.headers;
  const gatewayHeader = gateway.defaults.headers;
  const uuid = uuidv4();
  if (!justHeaders.common['x-session-id']) {
    justHeaders.common['x-session-id'] = uuid;
  }

  if (!gatewayHeader.common['x-session-id']) {
    gatewayHeader.common['x-session-id'] = uuid;
  }
};

export { justAPI, gateway, authAPI };
