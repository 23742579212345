import { useToast } from '@chakra-ui/react';
import { AuthCustomer } from '@getjust/api-schema';
import { useMutation } from '@tanstack/react-query';

import { useQueryError, useRouter } from '$hooks/client';
import { useEmailAtom, useROLoginAtom } from '$hooks/state';
import { USE_AUTH_LEGACY } from '$src/constants';
import { useAuth } from '$src/hooks/auth';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { authAPI } from '$src/http';

const MUTATION_LOGIN_CHALLENGE_KEY = 'MUTATION/LOGIN_CHALLENGE';

export const useLoginChallenge = () => {
  const toast = useToast();
  const router = useRouter();
  const loginInfo = useROLoginAtom();
  const { onEmailChange } = useEmailAtom();
  const queryError = useQueryError({});
  const { track } = useTrackAmplitude();
  const { setIsAuthenticated, setLegacyState } = useAuth();

  const mutation = useMutation(
    (values: AuthCustomer.AuthChallengeRequestBody) =>
      authAPI.post<AuthCustomer.AuthChallengeResponseBody>('/challenge', {
        ...values,
        clientId: 'web',
      }),
    {
      mutationKey: [MUTATION_LOGIN_CHALLENGE_KEY],
      onError: async (error: any) => {
        track('OTP failed');
        const title = queryError(error);
        toast({
          description: title,
          isClosable: true,
          status: 'error',
          position: 'top',
          duration: 10000,
        });
      },
      onSuccess: ({ data }) => {
        track('OTP succeeded');
        track('Customer logged in');
        if (USE_AUTH_LEGACY && data.outcome === 'loggedIn') {
          setLegacyState({ accessToken: data.accessToken ?? '', refreshToken: data.refreshToken ?? '' });
        }
        setIsAuthenticated(true);
        onEmailChange();
        router.push('/authenticated');
      },
    },
  );

  const onChallenge = async (code: string) =>
    await mutation.mutateAsync({
      code: code.toString(),
      userId: loginInfo!.userId!,
      sessionId: loginInfo!.sessionId!,
    });

  return {
    onChallenge,
    status: mutation.status,
  };
};
