import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Address, Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { gateway } from '$src/http';

export const UPDATE_SHIPPING_ADDRESS_MUTATION_KEY = 'MUTATION/UPDATE_SHIPPING_ADDRESS';

export const useUpdateShippingAddress = () => {
  const queryClient = useQueryClient();
  const baseUrl = useBaseApiUrl();
  const { track } = useTrackAmplitude();

  const mutation = useMutation(
    ({ address, email }: { address: Address; email: string }) =>
      gateway.post<Cart>(`${baseUrl}/update-shipping-address`, { address, email }),
    {
      mutationKey: [UPDATE_SHIPPING_ADDRESS_MUTATION_KEY],
      onSuccess: ({ data }) => {
        queryClient.setQueryData<Cart>([CART_QUERY_KEY], data);
        track('Shipping methods loaded');
      },
    },
  );

  return { updateShippingAddress: mutation.mutateAsync, state: mutation.status, reset: mutation.reset };
};
