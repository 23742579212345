import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CheckoutV2 } from '@getjust/api-schema';

import { USER_INFO_QUERY_KEY } from '$src/constants';
import { justAPI } from '$src/http';

const ADD_ADDRESS_MUTATION_KEY = 'MUTATION/ADD_ADDRESS';

export const useAddAddress = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (address: CheckoutV2.CreateProfileAddressRequestBody) =>
      justAPI.post<CheckoutV2.CreateProfileAddressResponseBody>('/v2/profile/address', address),
    {
      mutationKey: [ADD_ADDRESS_MUTATION_KEY],
      onSuccess: () => {
        queryClient.invalidateQueries([USER_INFO_QUERY_KEY]);
      },
    },
  );
};
