import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CheckoutV1 } from '@getjust/api-schema';

import { useUpdateShippingAddress } from '$hooks/mutations';
import { MUTATION_UPDATE_PROFILE, USER_INFO_QUERY_KEY } from '$src/constants';
import { useCountriesFromCache } from '$src/hooks/queries';
import { useEmailAtom } from '$src/hooks/state';
import { justAPI } from '$src/http';

export interface UserUpdatePayload extends CheckoutV1.AuthUpdateProfileRequestBody {
  updateCheckoutShippingMethods?: boolean;
}

export function useProfileUpdate() {
  const { email } = useEmailAtom();
  const { updateShippingAddress } = useUpdateShippingAddress();
  const countries = useCountriesFromCache();

  const queryClient = useQueryClient();

  return useMutation((user: UserUpdatePayload) => justAPI.put('/v1/profile', user), {
    mutationKey: [MUTATION_UPDATE_PROFILE],
    onSuccess: async (data, payload) => {
      if (payload.updateCheckoutShippingMethods) {
        const defaultAddress = payload?.addresses?.find((address) => address.is_default_shipping)?.address;
        if (defaultAddress?.address1) {
          const { phone } = payload;
          const { last_name: lastName, first_name: firstName } = payload.personal_informations;
          const { first_name, last_name, country_code, ...address } = defaultAddress;

          await updateShippingAddress({
            address: {
              firstName,
              lastName,
              phone,
              ...address,
              country: address.country!,
              countryCode: countries.find((country) => country.country === address.country!)?.value,
            },
            email: email ?? '',
          });
        }
      }

      await queryClient.invalidateQueries([USER_INFO_QUERY_KEY]);
    },
  });
}
