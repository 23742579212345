import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'next-i18next';

import type { ConfirmCartResult } from '@getjust/gateway';

import { useShop } from '$hooks/queries';
import { usePaymentStatusAtom, useROFormMessageAtom } from '$hooks/state';
import { ORDER_REQUEST_QUERY_KEY, USE_AUTH_LEGACY } from '$src/constants';
import { useAuth } from '$src/hooks/auth';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { usePostMessage } from '$src/hooks/client/usePostMessage';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { gateway } from '$src/http';
import { ConfirmInput } from '$src/pages/api/[shopId]/[cartId]/confirm';
import { captureMixpanelAnalytics, capturePosthogAnalytics } from '$src/utils';

export const useCreateOrder = () => {
  const { data: shop } = useShop();
  const message = useROFormMessageAtom();
  const { onPaymentStatusChange } = usePaymentStatusAtom();
  const { t } = useTranslation('common');
  const toast = useToast();
  const baseUrl = useBaseApiUrl();
  const queryClient = useQueryClient();
  const { setIsAuthenticated, setLegacyState } = useAuth();
  const { postMessage } = usePostMessage();
  const { track } = useTrackAmplitude();

  return useMutation((input: ConfirmInput) => gateway.post<ConfirmCartResult>(`${baseUrl}/confirm`, input), {
    onError: (error: AxiosError<ConfirmCartResult>) => {
      track('Order creation failed', { error: JSON.stringify(error.toJSON()) });
      switch (error?.response?.data?.outcome) {
        case 'notConfirmed':
          if (error.response.data.reason === 'threedSecured') {
            onPaymentStatusChange({
              display: true,
              url: error.response.data.nextAction ?? '',
              cartId: error.response.data.cartId,
            });
          }
          if (['cannotAddSource', 'paymentError'].indexOf(error.response.data.reason) !== -1) {
            const errorCode =
              error.response.data.reason === 'paymentError'
                ? error.response.data.code
                : // @ts-expect-error - error
                  error.response.data.error?.error?.code;
            toast({
              description: t(`errors.stripe.${errorCode}`),
              status: 'error',
              position: 'top',
              isClosable: true,
              duration: 10000,
            });
          }
          break;
        default:
          captureMixpanelAnalytics('CREATE_ORDER_FAILED', {
            seller: shop?.name,
            source: message!.source,
          });
          capturePosthogAnalytics('CREATE_ORDER_FAILED', {
            seller: shop?.name,
            source: message!.source,
          });
          break;
      }
    },
    onSuccess: async ({ data }, variables) => {
      track('Order created', {
        totalAmount: variables.cart.totalAmount,
        currency: variables.cart.currency,
        product: variables.cart.lineItems?.length,
      });
      postMessage({
        emitter: 'JUST_FORM',
        action: 'CLEAR_SESSION_ID',
      });

      captureMixpanelAnalytics('CREATE_ORDER_SUCCESS', {
        seller: shop?.name,
        source: message!.source,
      });
      capturePosthogAnalytics('CREATE_ORDER_SUCCESS', {
        seller: shop?.name,
        source: message!.source,
      });
    },
    onSettled: (response, error) => {
      if (
        // @ts-expect-error - expected
        response?.data?.login ||
        // @ts-expect-error - expected
        error?.response?.data?.login
      ) {
        if (USE_AUTH_LEGACY) {
          setLegacyState({
            accessToken:
              // @ts-expect-error always defined
              response?.data?.login?.accessToken ?? error?.response?.data?.login?.accessToken ?? '',
            refreshToken:
              // @ts-expect-error always defined
              response?.data?.login?.refreshToken ?? error?.response?.data?.login?.refreshToken ?? '',
          });
        }
        setIsAuthenticated(true);
      }
      switch (response?.data?.outcome) {
        case 'confirmed':
          onPaymentStatusChange({ display: false, url: undefined, cartId: response.data.cartId });
          queryClient.fetchQuery([ORDER_REQUEST_QUERY_KEY, response.data.cartId]);
          break;
      }
    },
  });
};
