import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { useCartAtom, useFormMessageAtom } from '$hooks/state';
import { CART_QUERY_KEY } from '$src/constants';
import { usePostMessage } from '$src/hooks/client/usePostMessage';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { gateway } from '$src/http';
import { PrepareCartInput } from '$src/pages/api/[shopId]/prepare-cart';

export const MUTATION_PREPARE_CART_KEY = 'MUTATION/PREPARE_CART';

export const usePrepareCart = () => {
  const { onCartChange } = useCartAtom();
  const { formMessage } = useFormMessageAtom();
  const queryClient = useQueryClient();
  const { postMessage } = usePostMessage();
  const { track } = useTrackAmplitude();

  return useMutation(
    (cartInput: PrepareCartInput) =>
      gateway.post<Cart>(`/api/${formMessage?.shopId}/prepare-cart`, cartInput),
    {
      mutationKey: [MUTATION_PREPARE_CART_KEY],
      onSuccess: ({ data }) => {
        onCartChange({ id: data!.id });
        queryClient.setQueryData<Cart>([CART_QUERY_KEY], data);
        postMessage({
          emitter: 'JUST_FORM',
          action: 'SET_SESSION_ID',
          cartId: data.id,
        });
        /* we do it here, as global MutationCache onSuccess callback is executed before this one. So, when it's executed,
         cartId state atom is not already set */
        if (data?.shipping?.ready === false) {
          queryClient.invalidateQueries([CART_QUERY_KEY]);
        }
        track('Checkout form loaded', {
          totalAmount: data.totalAmount,
          currency: data.currency,
          product: data.lineItems?.length,
        });
      },
      onError: (err, payload) => {
        track('Checkout form load failed', {
          payload: JSON.stringify(payload),
          discount: payload?.cart?.discountCode,
          variantsIds: payload?.cart?.lineItems?.map((item) => item.variantId).join(', '),
        });
      },
    },
  );
};
