import { CheckoutV1 } from '@getjust/api-schema';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UPDATE_SOURCES_MUTATION_KEY, USER_INFO_QUERY_KEY } from '$src/constants';
import { justAPI } from '$src/http';

export const useUpdateSource = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (source: CheckoutV1.SourcesUpdateSourceRequestBody) =>
      justAPI.put<CheckoutV1.SourcesUpdateSourceResponseBody>('/v1/sources', source),
    {
      mutationKey: [UPDATE_SOURCES_MUTATION_KEY],
      onMutate: async ({ card_id }) => {
        await queryClient.cancelQueries({ queryKey: [USER_INFO_QUERY_KEY] });
        const previousUser = queryClient.getQueryData<CheckoutV1.AuthMeResponseBody>([USER_INFO_QUERY_KEY]);
        queryClient.setQueryData<CheckoutV1.AuthMeResponseBody>(
          [USER_INFO_QUERY_KEY],
          (oldUser): CheckoutV1.AuthMeResponseBody | undefined => {
            const sourceIndex = oldUser?.sources?.findIndex((source) => source.id === card_id) ?? -1;
            if (sourceIndex === -1) return oldUser;
            const copySources = [...(oldUser?.sources ?? [])];
            copySources.forEach((source) => (source.is_default = 0));
            if (copySources[sourceIndex]) {
              copySources[sourceIndex]!.is_default = 1;
            }
            return {
              ...(oldUser as CheckoutV1.AuthMeResponseBody),
              sources: copySources,
            };
          },
        );
        return {
          previousUser,
        };
      },
      onSettled: () => {
        queryClient.invalidateQueries([USER_INFO_QUERY_KEY]);
      },
    },
  );
};
