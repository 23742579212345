import { AuthCustomer } from '@getjust/api-schema';
import { clearStorage } from '@getjust/commons';
import { useMutation } from '@tanstack/react-query';

import { useRouter } from '$hooks/client';
import { useLoginAtom } from '$src/hooks/state';
import { justAPI } from '$src/http';

const VALIDATE_EMAIL_MUTATION_KEY = 'MUTATION/VALIDATE_EMAIL';

export const useValidateEmail = () => {
  const router = useRouter();
  const { onLoginChange } = useLoginAtom();

  const mutation = useMutation(
    (input: AuthCustomer.AuthValidateEmailRequestBody) =>
      justAPI.post<AuthCustomer.AuthValidateEmailResponseBody>('/v2/auth/validate-email', input),
    {
      mutationKey: [VALIDATE_EMAIL_MUTATION_KEY],
      onSuccess: async ({ data }) => {
        if (data.outcome === 'validated') {
          onLoginChange(data);
          router.push(`/validate-email`);
        }
      },
      onError: async () => {
        router.push('/unauthenticated');
      },
      onMutate: async () => {
        clearStorage('localStorage', 'email');
      },
    },
  );

  const validateEmail = async (email: string) => mutation.mutateAsync({ email });

  return { validateEmail };
};
