import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'next-i18next';

import type { CheckoutV1 } from '@getjust/api-schema';

import { useCart } from '$hooks/queries';
import { useFormMessageAtom } from '$hooks/state/useFormMessageAtom';
import { usePaymentStatusAtom } from '$hooks/state/usePaymentStatusAtom';
import { MAX_FAILURE_COUNT, ORDER_REQUEST_QUERY_KEY } from '$src/constants';
import { useAuth } from '$src/hooks/auth';
import { usePostMessage } from '$src/hooks/client/usePostMessage';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useCleanupCart } from '$src/hooks/mutations/useCleanupCart';
import { justAPI } from '$src/http';

export const useOrderStatus = () => {
  const { formMessage, onFormMessageChange } = useFormMessageAtom();
  const { paymentStatus } = usePaymentStatusAtom();
  const { t } = useTranslation('common');
  const toast = useToast();
  const { data: cart } = useCart();
  const { authState } = useAuth() ?? {};
  const { postMessage } = usePostMessage();
  const { track } = useTrackAmplitude();
  const { mutateAsync: cleanUpCart } = useCleanupCart();
  return useQuery({
    queryKey: [ORDER_REQUEST_QUERY_KEY, paymentStatus?.cartId],
    queryFn: async () =>
      justAPI.get<CheckoutV1.OrdersGetStatusResponseBody>(`/v1/status/${paymentStatus?.cartId}`),
    select: (response) => response.data,
    enabled: Boolean(paymentStatus?.cartId) && authState === 'authenticated',
    retry: (_failCount: number, error: any) => {
      if (_failCount < MAX_FAILURE_COUNT && error?.response?.data?.reason === 'orderNotFound') {
        if (_failCount === 3) {
          toast({
            description: 'warningProcessing',
            status: 'info',
            position: 'top',
            duration: 5000,
            isClosable: true,
          });
        }
        return true;
      } else if (error?.response?.data?.outcome === 'notReady') {
        return false;
      } else {
        toast({
          description: 'failedOrder',
          status: 'warning',
          position: 'top',
          duration: 10000,
          isClosable: true,
        });
        return false;
      }
    },
    onError: (error: AxiosError<CheckoutV1.OrdersGetStatusResponseBody>) => {
      onFormMessageChange({
        commandStatus: 'ERROR',
      });

      if (
        error?.response?.data?.outcome === 'notReady' &&
        error?.response?.data?.reason === 'paymentError' &&
        error?.response?.data?.error
      ) {
        toast({
          description: t(`errors.stripe.${error?.response?.data?.error}`),
          title: t(`errors.commons.paymentError`),
          status: 'error',
          position: 'top',
          isClosable: true,
          duration: 10000,
        });

        if (error?.response?.data?.error === 'payment_intent_authentication_failure') {
          track('3DS check failed');
        }
      }
    },
    onSuccess: async (data) => {
      if (data?.outcome === 'ready') {
        try {
          await cleanUpCart();
        } catch {
          console.error('Failed to clean up cart');
        }
        postMessage({
          commandStatus: 'CREATED',
          orderUrl: data?.url,
          sessionKey: formMessage?.sessionKey ?? 'DEFAULT_KEY',
          emitter: 'JUST_FORM',
          action: 'NAVIGATE',
          fbq: {
            currency: cart?.currency ?? 'EUR',
            value: cart?.totalAmount ?? 0.0,
          },
          analytics: {
            address: {
              province: data?.analytics?.address?.province,
              provinceCode: data?.analytics?.address?.provinceCode,
              city: data?.analytics?.address?.city,
              country: data?.analytics?.address?.country,
              countryCode: data?.analytics?.address?.countryCode,
              zip: data?.analytics?.address?.zip,
            },
            cartId: cart?.id,
            orderId: data?.analytics?.orderId,
            name: data?.analytics?.name,
            totalAmount: data?.analytics?.totalAmount,
            shippingAmount: data?.analytics?.shippingAmount,
            taxAmount: data?.analytics?.taxAmount,
            totalDiscount: data?.analytics?.discountAmount,
            currency: data?.analytics?.currency,
            products: data?.analytics?.products,
          },
        });
      }
    },
  });
};
