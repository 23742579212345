import { useMemo } from 'react';

/**
 * NOTE: Minified version of _all-countries to reduce loading time. It only has attributes needed by useCountries(). Generate it using this snippet:
 *
 * const fs = require('fs');
 * const data = fs.readFileSync('_all-countries.json', 'utf8');
 * const countries = JSON.parse(data).map((e) => ({label: `${e.flag} ${e.name.common}`,value: e.cca2,country: e.name.common,flag: e.flag,regex: e?.postalCode?.regex})).sort((prev, next) => prev.label.localeCompare(next.label))
 * fs.writeFileSync('_minified-countries.json', JSON.stringify(countries));
 */
import countries from '$src/hooks/queries/useCountries/_minified-countries.json';

export function useCountriesFromCache() {
  const data = countries;

  return useMemo(() => data, [data]);
}
