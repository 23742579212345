import { AuthCustomer } from '@getjust/api-schema';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';

import { loginAtom } from '$business/atoms/login';
import { reportStateError } from '$src/errors';

export function useLoginAtom() {
  const [loginInfo, setLoginAtom] = useAtom(loginAtom);

  const onLoginChange = useCallback(
    (loginQueryInfo: Partial<AuthCustomer.AuthLoginResponseBody>) => {
      const safe = AuthCustomer.ZAuthLoginResponseBody.safeParse(loginQueryInfo);

      if (safe.success) {
        setLoginAtom(safe.data);
      } else {
        reportStateError(safe.error, {
          tags: {
            hook: useLoginAtom.name,
          },
        });
      }
    },
    [setLoginAtom],
  );

  return { loginInfo, onLoginChange };
}

export function useROLoginAtom() {
  const loginInfo = useAtomValue(loginAtom);

  return loginInfo;
}
