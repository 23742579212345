import { useEffect } from 'react';

import { useMessageListenner } from '$src/hooks/client';
import { addIdentify } from '$src/hooks/client/useTrackAmplitude';
import { usePrepareCart } from '$src/hooks/mutations';
import { useCart, useUserLocationDetection } from '$src/hooks/queries';
import { useCartAtom, useCountryCodeAtom, useROFormMessageAtom } from '$src/hooks/state';
import { setXLanguageGatewayHeader, setXSessionHeaders } from '$src/http';

interface InitWrapperProps {
  children: any;
}

export const InitWrapper = ({ children }: InitWrapperProps) => {
  const message = useROFormMessageAtom();

  useEffect(() => {
    addIdentify('language', message?.language ?? '-');
  }, [message?.language]);

  useMessageListenner();
  useEffect(() => {
    setXSessionHeaders();
  }, []);
  const { data: location } = useUserLocationDetection();
  const { onCountryCodeChange, country } = useCountryCodeAtom();

  useEffect(() => {
    onCountryCodeChange(location?.location.country.code);
  }, [location, onCountryCodeChange]);

  useEffect(() => {
    setXLanguageGatewayHeader(message?.language ?? 'fr');
  }, [message?.language]);

  const { cart: { id: cartId } = {} } = useCartAtom();
  const { data: cart, refetch } = useCart();

  const { mutateAsync: prepareCart, status: prepareCartStatus } = usePrepareCart();

  useEffect(() => {
    if (
      !cartId &&
      message?.cart &&
      prepareCartStatus !== 'error' &&
      prepareCartStatus !== 'loading' &&
      location?.location.latitude !== 0 &&
      country
    ) {
      prepareCart({
        cart: message.cart,
        latitude: location?.location.latitude,
        longitude: location?.location.longitude,
      });
    }
  }, [cartId, country, message, prepareCart, prepareCartStatus, location]);

  useEffect(() => {
    if (cart?.shipping?.ready === false) {
      refetch();
    }
  }, [cart, refetch]);

  return <>{children}</>;
};
