import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

export const captureMixpanelAnalytics = (name: string, props?: { [key: string]: any }) => {
  if (!process.env.NEXT_PUBLIC_ENABLE_ANALYTICS) {
    return;
  }

  return mixpanel.track(name, props);
};

export const capturePosthogAnalytics = (
  name: string,
  props?: {
    user?: string;
    registered?: boolean;
    seller?: string;
    [key: string]: any;
  },
) => {
  if (!process.env.NEXT_PUBLIC_ENABLE_ANALYTICS) {
    return;
  }

  return posthog.capture(name, props);
};
