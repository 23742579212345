import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'next-i18next';

import StarLoadingAnimation from '$animations/starLoading.json';
import { useShop } from '$src/hooks/queries';

export const FormLoader = () => {
  const { t, i18n } = useTranslation();
  const { data: shop } = useShop();

  return (
    <Box
      as="main"
      flex="1 1 auto"
      overflow="auto"
      p="0"
      m="0"
      height="100%"
      display="flex"
      backgroundColor="white"
      flexDirection="column"
      justifyContent="start"
      alignItems="center"
      alignContent="center"
      alignSelf="center"
    >
      <Stack spacing={0} alignItems="center" alignContent="center" alignSelf="center" pt="13rem">
        <Player src={StarLoadingAnimation} autoplay loop speed={1} style={{ height: '12.5rem' }}>
          <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
        </Player>
        <Text
          color="var(--Grey-300, #1C1C1C)"
          textAlign="center"
          fontWeight="500"
          noOfLines={2}
          width="12.625rem"
        >
          {t('loader.textFormLoader')}
        </Text>
      </Stack>

      {shop?.name !== undefined && (
        <Stack pb={10} spacing={1.5} position="absolute" bottom="0">
          <Text color="#8F8F8F" textAlign="center" fontSize="xs" fontWeight="350">
            {t('loader.authorizedBy') + ' ' + shop.name}
          </Text>
          <HStack spacing={1}>
            <Text color="#8F8F8F" textAlign="center" fontSize="xs" fontWeight="350">
              {t('secured_payment')}
            </Text>
            <a
              href={
                i18n.language === 'fr'
                  ? 'https://www.getjust.eu/fr/consommateurs'
                  : 'https://www.getjust.eu/shoppers'
              }
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
              rel="noreferrer"
            >
              <Image alt="JUST" src="/images/smallJust.svg" />
            </a>
          </HStack>
        </Stack>
      )}
      <Stack spacing={4}></Stack>
    </Box>
  );
};
