import { Identify, identify, setUserId, track } from '@amplitude/analytics-browser';
import posthog from 'posthog-js';
import { useEffect } from 'react';

import { useShop, useUserInfo } from '$src/hooks/queries';
import { useROFormMessageAtom } from '$src/hooks/state';

export interface KeyValue {
  [key: string]: string | number | undefined;
}

export const addIdentify = (name: string, value: string) => {
  const identifyEvent = new Identify();
  identifyEvent.set(name, value);
  identify(identifyEvent);
};

export const useTrackAmplitude = () => {
  const { data: shop } = useShop();
  const message = useROFormMessageAtom();
  const { data: currentUser } = useUserInfo();

  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
      return;
    }
    if (currentUser?.rawResponse?.id) {
      setUserId(currentUser?.rawResponse?.id);
    }
  }, [currentUser?.rawResponse?.id]);

  const trackEvent = (name: string, props?: KeyValue) => {
    if (!process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
      return;
    }
    try {
      return track(name, {
        ...props,
        shop: shop?.name,
        language: message?.language,
        shopId: message?.shopId,
        platform: shop?.platformType,
        source:
          message?.source === 'product-form'
            ? 'product'
            : message?.source === 'customV2'
            ? 'custom'
            : message?.source,
        posthogSessionId: posthog?.sessionManager?.checkAndGetSessionAndWindowId(),
      });
    } catch (e) {
      console.error('Failed to track with Amplitude', e);
      return;
    }
  };

  return {
    track: trackEvent,
  };
};
