import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';

import { QueryParamsAtom, queryParamsAtom } from '$business/atoms/query-params';
import { ZFormQueryParams } from '$models/http';
import { reportStateError } from '$src/errors';

export function useQueryParamsAtom() {
  const [queryParams, setQueryParams] = useAtom(queryParamsAtom);

  const onQueryParamsChange = useCallback(
    (params: Partial<QueryParamsAtom>) => {
      const safe = ZFormQueryParams.safeParse({
        ...(queryParams ?? {}),
        ...params,
      });

      if (safe.success) {
        setQueryParams(safe.data);
      } else {
        reportStateError(safe.error, {
          tags: {
            hook: useQueryParamsAtom.name,
          },
        });
      }
    },
    [queryParams, setQueryParams],
  );

  return { queryParams, onQueryParamsChange };
}

export function useROQueryParamsAtom() {
  const queryParams = useAtomValue(queryParamsAtom);

  return queryParams;
}
