/* eslint-disable @typescript-eslint/no-non-null-assertion */

import type {
  HttpStatusCode,
  HttpStatusText,
  Method,
  QueryError,
  QueryErrorType,
  QueryNetworkError,
  QueryRequestError,
  QueryServerError,
  Url,
} from '$src/types';
import type { AxiosError } from 'axios';

import { reportError } from '$src/errors';

export const isNetworkError = <T>(queryError: QueryError<T>): queryError is QueryNetworkError =>
  queryError.type === 'NetworkError';
export const isRequestError = <T>(queryError: QueryError<T>): queryError is QueryRequestError<T> =>
  queryError.type === 'RequestError';
export const isServerError = <T>(queryError: QueryError<T>): queryError is QueryServerError =>
  queryError.type === 'ServerError';
export const isQueryError = <T>(error: Error): error is QueryError<T> =>
  error instanceof QueryNetworkErrorClass ||
  error instanceof QueryRequestErrorClass ||
  error instanceof QueryServerErrorClass;

export function reportQueryError<T>(error: QueryError<T>): void {
  if (isNetworkError(error)) {
    return;
  }

  reportError(error, {
    level: 'debug',
    contexts: {
      request: {
        url: error.url,
        method: error.method,
      },
      response: {
        status: error.status,
        statusText: error.statusText,
        ...(isRequestError(error) ? { data: error.data } : {}),
      },
    },
    tags: {
      errorCategory: 'api',
      errorType: error.type,
      errorUrl: error.url,
      errorHttpMethod: error.method,
      errorHttpStatus: error.status,
    },
  });
}

export const fromAxiosError = async <T>(axiosError: AxiosError<T>): Promise<QueryError<T>> => {
  const errorType = getQueryErrorTypeFromStatus(axiosError.response?.status);

  switch (errorType) {
    case 'NetworkError':
      return new QueryNetworkErrorClass({
        originalError: axiosError,
        url: axiosError.config?.url ?? 'unknown',
        method: (axiosError.config?.method ?? 'GET').toUpperCase() as Method,
      });

    case 'RequestError':
      return new QueryRequestErrorClass({
        status: axiosError.response!.status,
        statusText: axiosError.response!.statusText,
        data: axiosError.response!.data,
        originalError: axiosError,
        url: axiosError.config?.url ?? 'unknown',
        method: (axiosError.config?.method ?? 'GET').toUpperCase() as Method,
      });

    default:
      return new QueryServerErrorClass({
        status: axiosError.response!.status,
        statusText: axiosError.response!.statusText,
        originalError: axiosError,
        url: axiosError.config?.url ?? 'unknown',
        method: (axiosError.config?.method ?? 'GET').toUpperCase() as Method,
      });
  }
};

const getQueryErrorTypeFromStatus = (status: number | undefined): QueryErrorType => {
  if (status === 0 || status === undefined) {
    return 'NetworkError';
  }

  if (status >= 400 && status < 499) {
    return 'RequestError';
  }

  return 'ServerError';
};

class QueryNetworkErrorClass extends Error implements QueryNetworkError {
  isQueryError = true as const;
  type = 'NetworkError' as const;
  originalError?: Error;
  method: Method;
  url: Url;

  constructor({ originalError, method, url }: { originalError?: Error; method: Method; url: Url }) {
    super('API request failure: Network error');
    Object.setPrototypeOf(this, QueryNetworkErrorClass.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, QueryNetworkErrorClass);
    }
    this.name = this.type;
    this.originalError = originalError;
    this.method = method;
    this.url = url;
  }

  public toJSON() {
    return {
      name: this.name,
      message: this.message,
      type: this.type,
      url: this.url,
      method: this.method,
    };
  }
}

class QueryRequestErrorClass<T> extends Error implements QueryRequestError<T> {
  originalError?: Error;
  isQueryError = true as const;
  type = 'RequestError' as const;
  status: HttpStatusCode;
  statusText: HttpStatusText;
  data: T;
  method: Method;
  url: Url;

  constructor({
    status,
    statusText,
    data,
    originalError,
    method,
    url,
  }: {
    status: HttpStatusCode;
    statusText: HttpStatusText;
    data: T;
    originalError?: Error;
    method: Method;
    url: Url;
  }) {
    super('API request failure: Bad request error');
    Object.setPrototypeOf(this, QueryRequestErrorClass.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, QueryRequestErrorClass);
    }

    this.name = this.type;
    this.originalError = originalError;
    this.status = status;
    this.statusText = statusText;
    this.data = data;
    this.method = method;
    this.url = url;
  }

  public toJSON() {
    return {
      name: this.name,
      message: this.message,
      type: this.type,
      status: this.status,
      statusText: this.statusText,
      data: this.data,
      url: this.url,
      method: this.method,
    };
  }
}

class QueryServerErrorClass extends Error implements QueryServerError {
  originalError?: Error;
  isQueryError = true as const;
  type = 'ServerError' as const;
  status: HttpStatusCode;
  statusText: HttpStatusText;
  method: Method;
  url: Url;

  constructor({
    status,
    statusText,
    originalError,
    method,
    url,
  }: {
    status: HttpStatusCode;
    statusText: HttpStatusText;
    originalError?: Error;
    method: Method;
    url: Url;
  }) {
    super('API request failure: Server error');
    Object.setPrototypeOf(this, QueryServerErrorClass.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, QueryServerErrorClass);
    }

    this.name = this.type;
    this.originalError = originalError;
    this.status = status;
    this.statusText = statusText;
    this.method = method;
    this.url = url;
  }

  public toJSON() {
    return {
      name: this.name,
      message: this.message,
      type: this.type,
      status: this.status,
      statusText: this.statusText,
      url: this.url,
      method: this.method,
    };
  }
}
