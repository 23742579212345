import * as Sentry from '@sentry/nextjs';

import type { CaptureContext } from '@sentry/types';

export const attachTagToFutureErrors = Sentry.setTag;

export function reportError<T extends Error>(error: T, options?: CaptureContext): void {
  Sentry.captureException(error, options);
}

export function reportStateError<T extends Error>(error: T, options?: CaptureContext): void {
  Sentry.captureException(error, {
    ...options,
    tags: {
      // @ts-expect-error tag may be defined
      ...(options?.tags ?? {}),
      type: 'JOTAI_STATE_ERROR',
    },
  });
}
