import { useQuery } from '@tanstack/react-query';

import type { ReshapedUser } from '$src/types';
import type { CheckoutV1 } from '@getjust/api-schema';

import { useRouter } from '$hooks/client';
import { useShop } from '$hooks/queries';
import { useCountryCodeAtom, useEmailAtom } from '$hooks/state';
import { USER_INFO_QUERY_KEY } from '$src/constants';
import { AuthStatuses, useAuth } from '$src/hooks/auth';
import { justAPI } from '$src/http';

export const useUserInfo = () => {
  const { authState } = useAuth() ?? {};
  const router = useRouter();
  const { data: shop } = useShop();
  const { changeCountry } = useCountryCodeAtom();
  const { onEmailChange } = useEmailAtom();

  return useQuery({
    queryKey: [USER_INFO_QUERY_KEY],
    select: (response) => reshapeCurrentUserData(response),
    queryFn: async () => {
      const response = await justAPI.get<CheckoutV1.AuthMeResponseBody>('/v1/auth/me');
      return response.data;
    },

    enabled: authState === AuthStatuses.AUTHENTICATED && Boolean(shop),
    onSuccess: (data) => {
      changeCountry(data?.user?.address?.country);
      onEmailChange(data?.user.email);
    },
    onError: async (error: any) => {
      if (error?.type === 'RequestError') {
        switch (error.status) {
          case 404:
            router.push('/unauthenticated');
            break;
          default:
            return;
        }
      }
    },
  });
};

export function reshapeCurrentUserData(data: CheckoutV1.AuthMeResponseBody): ReshapedUser {
  const address = data?.addresses?.find((address) => address.is_default_shipping);
  const billing_address = data?.addresses?.find((address) => address.is_default_billing) ?? address;
  const formattedSources = (data.sources ?? []).flatMap((source) => {
    return source.card
      ? {
          id: source.id,
          is_default: !!source.is_default,
          ...source.card,
          last4: source.card.last_4, // @TODO should be sent directly like this by backend
        }
      : [];
  });

  return {
    user: {
      email: data.email,
      user: {
        lastname: data?.personal_informations?.last_name,
        firstname: data?.personal_informations?.first_name,
        phone_number: data?.phone,
      },
      address: address
        ? {
            ...address?.address,
            id: address?.id,
            is_default_billing: billing_address!.is_default_billing,
            is_default_shipping: address!.is_default_shipping,
          }
        : undefined,

      billing_address: billing_address
        ? {
            ...billing_address?.address,
            id: billing_address?.id,
            is_default_billing: billing_address!.is_default_billing,
            is_default_shipping: address!.is_default_shipping,
          }
        : undefined,
      sources: {
        cards: formattedSources,
        selected: formattedSources?.find((source) => source.is_default),
      },
    },
    formattedResponse: {
      ...data,
      addresses: data?.addresses?.map(({ address, ...rest }) => ({
        ...address,
        ...rest,
      })),
    },
    rawResponse: data,
  };
}
